import React from "react";

const PolicyEnglish = () => {
  return (
    <>
      <h2>Privacy Policy</h2>
      <p>
        This is a statement regarding the processing of personal data in
        accordance with the EU General Data Protection Regulation (2016/679)
        (GDPR)
      </p>
      <p>
        This privacy policy ("Policy") describes how [Company] ("Data
        Controller") collects and processes the personal data of the data
        subject in connection with the data subject [e.g., "registering for and
        using [Company's] Service"].
      </p>
      <p>Below is information about, among other things:</p>
      <ul>
        <li>Data Controller.</li>
        <li>Basis and Purpose for Processing Personal Data.</li>
        <li>Rights of the Data Subject.</li>
      </ul>
      <h4>Data Controller</h4>
      <ul className="list-unstyled">
        <li>Ultra tech sweden AB</li>
        <li>Organization Number: 5594418765</li>
      </ul>
      <h4>Contact information for data protection matters</h4>
      <p>
        In all matters related to the processing of personal data and situations
        concerning the exercise of individual rights, the data subject should
        contact the data controller in writing by sending an email to
        info@Flyproview.com
      </p>
      <h4>Registry name</h4>
      <p>The personal data is collected on www.FlyProView.com</p>
      <h4>Basis and purpose for processing personal data</h4>
      <p>The legal basis for processing personal data is:</p>
      <ul>
        <li>The data subject's consent to the processing of personal data</li>
        <li>
          The contractual relationship between the data subject and the data
          controller The purposes of processing personal data are to enable the
          booking of flight tickets and hotel rooms and to perform necessary
          actions for the website to fulfill its function.
        </li>
      </ul>
      <h4>Processed personal data</h4>
      <p>
        The data controller only collects the personal data from the data
        subject that are relevant and necessary for the purposes described in
        this privacy policy.
      </p>
      <p className="fst-italic">
        The following information is processed about data subjects:
      </p>
      <ul className="fst-italic">
        <li>Name</li>
        <li>Age</li>
        <li>Phone number</li>
        <li>Email</li>
        <li>Location</li>
        <li>Address</li>
        <li>Passport issuance date</li>
        <li>Passport expire date</li>
      </ul>
      <h4>Data retention period</h4>
      <p>
        The data controller processes the personal data for 18 months. After
        this period, the data controller will delete or anonymize the data
        within 1 month in accordance with its deletion processes. The data
        controller may be obliged to process certain personal data in the
        register for a longer period than indicated above to comply with legal
        requirements or regulatory authorities.
      </p>
      <h4>Rights of the data subject</h4>
      <p className="fst-italic fw-semibold">Right to access personal data:</p>
      <p className="fst-italic">
        The data subject has the right to receive confirmation if their personal
        data is being processed and, if processed, the right to obtain a copy of
        their personal data
      </p>
      <p className="fst-italic fw-semibold">Right to rectification of data:</p>
      <p className="fst-italic">
        The data subject has the right to request correction of incomplete or
        inaccurate personal data concerning them. The data subject also has the
        right to complete incomplete personal data by submitting the necessary
        additional information.
      </p>
      <p className="fst-italic fw-semibold">Right to erasure of data:</p>
      <p className="fst-italic">
        The data subject has the right to request the erasure of their personal
        data. This applies, among other things, when the personal data is no
        longer needed for the purposes for which it was collected, or when the
        personal data has been processed unlawfully, or if the data subject has
        revoked the consent on which the processing is based.
      </p>
      <p className="fst-italic fw-semibold">Right to restriction of processing:</p>
      <p className="fst-italic">
        The data subject has the right to request the restriction of the
        processing of their personal data, among other things, if the data
        subject disputes the accuracy of the data or believes that the
        processing is unlawful.
      </p>
      <p className="fst-italic fw-semibold">Right to object:</p>
      <p className="fst-italic">
        The data subject has the right to object to processing based on
        legitimate interests. If personal data is processed for direct
        marketing, the data subject always has the right to object to the
        processing of their personal data for such marketing, including
        profiling for direct marketing.
      </p>
      <p className="fst-italic fw-semibold">Right to withdraw consent:</p>
      <p className="fst-italic">
        The data subject has the right to withdraw their consent to processing
        at any time without affecting the lawfulness of processing based on
        consent before its withdrawal.
      </p>
      <p className="fst-italic fw-semibold">Right to data portability:</p>
      <p className="fst-italic">
        The data subject has the right to receive the personal data concerning
        them, as well as the personal data they have provided. The personal data
        shall be provided in a structured, commonly used, and machine-readable
        format. The data subject also has the right to transmit this data to
        another data controller.
      </p>

      <p className="fst-italic fw-semibold">
        Right to lodge a complaint with the supervisory authority:
      </p>
      <p className="fst-italic">
        The national supervisory authority for matters related to personal data
        is the Swedish Data Protection Authority (Integritetsskydd Myndigheten).
        The data subject has the right to lodge a complaint with the supervisory
        authority if they believe that the processing of their personal data
        violates applicable legislation.
      </p>
    </>
  );
};

export default PolicyEnglish;
