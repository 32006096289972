import React, { useEffect, useState } from "react";
import {
  FaPlaneDeparture,
  FaPlaneArrival,
  FaLocationDot,
} from "react-icons/fa6";
import { PiAirplaneTiltFill } from "react-icons/pi";
import apis from "services";
import { Spinner } from "react-bootstrap";
import { cities } from "./common/cities";

const Search = ({
  type,
  cityDestinationCode,
  cityCurrentCode,
  setCityCode,
  value,
  setInputValue,
  inputValue,
  placeholder,
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  // console.log(cityCurrentCode, cityDestinationCode, "cityCurrentCode");
  
  const [cityName, setCityName] = useState("");
  const getCityCodes = async () => {
    const airports = await cities?.filter((city)=>city?.city?.toLowerCase()?.includes(cityName.toLowerCase()));;
    const limitedAirports = airports.slice(0, 50);
    let excludeSelectedCity = [];
    if (type === "departure") {
      excludeSelectedCity = cityDestinationCode
        ? limitedAirports?.filter((e) => e?.iata_code !== cityDestinationCode)
        : limitedAirports;
    } else if (type === "arrival") {
      excludeSelectedCity = cityCurrentCode
        ? limitedAirports?.filter((e) => e?.iata_code !== cityCurrentCode)
        : limitedAirports;
    }
    setOptions(excludeSelectedCity);
    // setLoading(false);
  };

  // console.log(searchResults,"22222")

  const handleInputChange = (event) => {
    const value = event.target.value;
    setCityName(value);
    getCityCodes(value);
    // setCityCode("");
    // setInputValue("");
  };

  useEffect(() => {
    const code = sessionStorage.getItem(
      value === "departure" ? "fromCode" : "toCode"
    );
    // console.log("CODE", code);
    if (code) {
      setCityCode(`${code}`);
    }
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       setLoading(true);
  //       const response = await apis.searchCode(inputValue);
  //       let result = response?.data?.map((data) => data);
  //       setOptions(result);
  //       setLoading(false);
  //     } catch (e) {
  //       console.log(e.message);
  //       setLoading(false);
  //     }
  //   };

  //   if (inputValue !== "") {
  //     fetchData();
  //   }
  // }, [inputValue]);

  useEffect(()=>{
setCityName(inputValue);
  },[inputValue])

  const handleFocus = () => {
    setShowOptions(true);
  };

  const handleBlur = () => {
    setShowOptions(true);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      const optionCard = document.getElementById("option_card");
      if (optionCard && !optionCard.contains(event.target)) {
        setShowOptions(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div class="position-relative w-100">
      {/* {value === "Departure" && (
        <FaPlaneDeparture class="plane_Icon fs-4 text-light" />
      )}
      {value === "Arrival" && (
        <FaPlaneArrival class="plane_Icon fs-4 text-light" />
      )}
       {value === "City" && (  
      <FaLocationDot class="plane_Icon fs-4 text-white" />
      )} */}
      <input
        type="text"
        placeholder={value}
        class="flight_input w-100"
        value={cityName}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />

      {loading && (
        <span className="search_spinner">
          {/* <Spinner/> */}
          <div class="search-loader"></div>
        </span>
      )}

      {showOptions && options?.length > 0 && (
        <div className="option_card shadow-sm p-0" id="option_card">
          {options?.map((option,index) => (
            <div
            key={index}
              className="flight_option py-1 px-2"
              onClick={() => {
                {/* if (value === "departure") {
                  sessionStorage.setItem("from", option?.city);
                } else if (value === "arrival") {
                  sessionStorage.setItem("to", option?.city);
                } */}
                setCityName(option?.city);
                setCityCode(option?.iata_code);
                setInputValue(option?.city);
                setOptions([]);
                setShowOptions(false);
              }}
            >
              {/* <PiAirplaneTiltFill className="text-white" />  */}
              <div>
                <span>{option?.city}</span>
                <p>{option?.name}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export { Search };
