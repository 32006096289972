import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FlightBookingDetails from "screens/FlightBookingDetails";
import FlightDetail from "screens/FlightDetail";
import ForgotPassword from "screens/ForgotPassword";
import Login from "screens/Login";
import Otp from "screens/Otp";
import Profile from "screens/Profile";
import SearchFlight from "screens/SearchFlight";
import SignUp from "screens/SignUp";
import UpdatePassword from "screens/UpdatePassword";
import Bookings from "./screens/Bookings";
import ContactUs from "./screens/ContactUs";
import ScrollToTop from "components/ScrollToTop";
import Community from "./screens/Community";
import AboutUs from "screens/AboutUs";
import PrivateRoute from "PrivateRoute";
import TermsCondition from "screens/TermsCondition";
import StripeWrapper from "StripeWrapper";
import Ticket from "screens/Ticket";

import { io } from "socket.io-client";
const socket = io('http://localhost:8000/');


function App() {

socket.on("ticketSuccess", (data) => {
  console.log(data, "userDatauserDatauserData")
});
  return (
    <>
 
      <ScrollToTop />
        <Routes>
          
        <Route path="/test-payment" element={<StripeWrapper />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/otp_verification" element={<Otp />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/update_password" element={<UpdatePassword />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/" element={<SearchFlight />} />
          <Route path="*" element={<SearchFlight />} />
          <Route path="/ticket" element={<Ticket />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/flight_search" element={<SearchFlight />} />
          <Route path="/flight_details" element={<FlightDetail />} />
          <Route path="/terms_conditions" element={<TermsCondition />} />
         
          {/* <Route path="*" element={<Home />} /> */}
          {/* <Route
            path="/flight_booking_details"
            element={<FlightBookingDetails />}
          /> */}
          <Route element={<PrivateRoute />}>
          <Route path="/community" element={<Community />} />
            <Route path="/bookings" element={<Bookings />} />
          </Route>
        </Routes>
    
    </>
  );
}

export default App;
