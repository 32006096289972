import React from "react";
import { useNavigate } from "react-router-dom";
// import { FaEuroSign } from "react-icons/fa6";
import FlightItineraries from "./FlightItineraries";
// import { useSelector } from "react-redux";
// import { toast } from "react-hot-toast";

const FlightSearchResult = ({minDuration,minimumPrice, item, othersData, from, to }) => {
  // const { user } = useSelector((store) => store.user);
  const flightClass =
    item?.travelerPricings?.[0]?.fareDetailsBySegment?.[0]?.cabin;

  const navigate = useNavigate();
  const airlineData = othersData.carriers;
  const locationData = othersData.locations;
// city name data
  const getCityName = (iataCode) => {
    const cityData = locationData?.[iataCode];
    if (cityData) {
      return `${cityData?.cityCode}`;
    }
    return "Unknown City";
  };

  // console.log(minimumPrice,"minimumPriceminimumPriceminimumPrice")

  return (
    <div className="avalible-flight-list">
      <div className="avalible-flight-list-wrap">
        {/* flight iterations cards */}
        <FlightItineraries
           minimumPrice={minimumPrice}
          airlineData={airlineData}
          item={item}
          getCityName={getCityName}
          flightClass={flightClass}
          from={from}
          to={to}
          minDuration={minDuration}
        />
        <div className="book-now-flight">
          <div className="book-now-flight-left"></div>
          <div className="book-now-flight-center">
            <ul>
              {/* <li>
                <svg width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1.34394" y="0.561955" width="52.8237" height="52.8237" rx="3.93368" stroke="#545860" stroke-width="1.12391" />
                  <path d="M32.0112 19.2469C29.1611 19.2469 27.7562 22.0567 27.7562 22.0567C27.7562 22.0567 26.3513 19.2469 23.5011 19.2469C21.1848 19.2469 19.3506 21.1848 19.3269 23.4972C19.2786 28.2971 23.1345 31.7105 27.3611 34.5791C27.4776 34.6584 27.6153 34.7008 27.7562 34.7008C27.8971 34.7008 28.0348 34.6584 28.1513 34.5791C32.3774 31.7105 36.2334 28.2971 36.1855 23.4972C36.1618 21.1848 34.3275 19.2469 32.0112 19.2469V19.2469Z" stroke="#4C4850" stroke-width="1.68586" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </li> */}
              <li>
                <button
                 
                  onClick={() => {
                    // if (user) {
                    //   navigate("/flight_details", {
                    //     state: { item, locationData },
                    //   });
                    // } else {
                    //   toast.error("Plz Login first");
                    // }
                    navigate("/flight_details", {
                          state: { item, locationData },
                        });
                  }}
                >
                  Book Now
                </button>
              </li>
            </ul>
          </div>
          <div className="book-now-flight-right"></div>
        </div>
        {/* <div class="col-lg-2 d-flex flex-column justify-content-around">
          <div class="my-3">
          <div>
          <h6 class="text-center d-flex align-items-center justify-content-center gradient__text mb-4">
              {item?.itineraries?.[0]?.segments?.length - 1 === 0 ? "Direct Flight" : 
              `No of stops : ${item?.itineraries?.[0]?.segments?.length - 1}`
              }
            </h6>
      
         </div>

            <h3 class="text-center d-flex align-items-center justify-content-center gradient__text">
              <FaEuroSign className="text-dark-red" /> {item?.price?.grandTotal}{" "}
            </h3>

            <button
              class="gradient_btn my-3 w-100 shadow"
              onClick={() => {
                if (user) {
                  navigate("/flight_details", {
                    state: { item, locationData },
                  });
                } else {
                  toast.error("Plz Login first");
                }
              }}
            >
              Book Now
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default FlightSearchResult;
