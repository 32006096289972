import React, { useState } from "react";
import formatDuration, { formatDurationForDays } from "helpers/formatDuration";

import Accordion from "react-bootstrap/Accordion";
import { MdOutlineEuro } from "react-icons/md";
// import {
//   FaPlane,
//   FaArrowRightLong,
//   FaCalendarDays,
//   FaRegClock,
// } from "react-icons/fa6";
import Logo from "./Logo";
import { cities } from "./common/cities";
import dayjs from "dayjs";
import moment from "moment";

const FlightItineraries = ({
  minimumPrice,
  item,
  getCityName,
  airlineData,
  minDuration
}) => {
  const getAirlineName = (code) => {
    return airlineData?.[code] || "";
  };

  const [activeKey, setActiveKey] = useState(null);

  const handleAccordionClick = (eventKey) => {
    setActiveKey(activeKey === eventKey ? null : eventKey);
  };
// city name
  const getCityDataName = (iataCode) => {
    const [cityName] = cities.filter(c => c.iata_code === iataCode)
    return cityName ? cityName?.city : "";
  };

  // console.log(minimumPrice, "minimumPriceminimumPrice");
// calculate duration
  const calculateTotalDuration = (itinerary) => {
    return itinerary.segments.reduce((totalDuration, segment) => {
      return totalDuration + segment.duration;
    }, 0);
  };
  

  // console.log(item?.itineraries?.[0]?.duration,"item?.itineraries?.[0]?.duration")
  // total duration per sagement
  let totalDaysHours = formatDurationForDays(item?.itineraries?.[0]?.duration);
  return (
    <>
      <Accordion className="w-100 border-0" activeKey={activeKey}>
        {/* <h4 class="d-flex align-items-center justify-content-start gradient__text m-4 mt-2">
          {getAirlineName(item?.itineraries?.[0]?.segments?.[0]?.carrierCode)}
        </h4> */}
        {item?.itineraries?.map((itinerary, itineraryIndex) => {
          let arrival =
            itinerary?.segments[itinerary?.segments?.length - 1]?.arrival?.at;

          let date1 = moment(itinerary?.segments?.[0].departure?.at);
          let date2 = moment(arrival);
          let daysDiff = date2?.diff(date1, "days");

          return (
            <div
            key={itineraryIndex}
              className={`${
                itinerary?.segments?.length === 1 && "directFlight"
              }`}
            >
              <Accordion.Item
                key={itineraryIndex}
                eventKey={itineraryIndex.toString()}
              >
                {itineraryIndex === 1 && (
                  <div className="return-flight">
                    <div className="return-flight-wrap">
                      <div className="return-flight-wrap-left"></div>
                      <div className="return-flight-wrap-center">
                        <hr />
                        <span className="">Return</span>
                      </div>
                      <div className="return-flight-wrap-right"></div>
                    </div>
                  </div>
                )}
                <div
                  className={`flight-list-container ${
                    itineraryIndex === 1 ? "retur-value" : ""
                  }`}
                >
                  <div className="left">
                    <Logo
                      name={getAirlineName(
                        itinerary?.segments?.[0]?.carrierCode
                      )}
                      code={itinerary?.segments?.[0]?.carrierCode}
                    />
                  </div>
                  <div className="center">
                    <Accordion.Header
                      className={
                        activeKey === itineraryIndex.toString()
                          ? "custom-active-accordion"
                          : ""
                      }
                      onClick={() =>
                        handleAccordionClick(itineraryIndex.toString())
                      }
                    >
                      <div
                        key={itineraryIndex}
                        className="flight-list-container-head"
                      >
                        <div className="head-top">
                          <div className="head-top-wrap position-relative">
                            <li className="position-absolute list-unstyled direct-stops-text  top-100 start-50 mt-3 mt-md-4 pt-5  pt-md-5 translate-middle">
                              {itinerary?.segments?.length > 1
                                ? itinerary?.segments?.length -1 + " stops"
                                : "Direct"}
                            </li>
                            <ul className="position-absolute top-100 start-50 pb-1 mt-3 translate-middle">
                              <li>
                                <svg
                                  width="19"
                                  height="17"
                                  viewBox="0 0 19 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6.92546 16.2188H5.93761C5.83835 16.2187 5.74068 16.1938 5.65354 16.1463C5.56639 16.0988 5.49255 16.0301 5.43877 15.9467C5.38499 15.8633 5.35298 15.7677 5.34567 15.6687C5.33836 15.5697 5.35598 15.4705 5.39692 15.3801L7.78899 10.1013L4.19718 10.0215L2.88722 11.6087C2.63747 11.9226 2.43819 12.0625 1.92979 12.0625H1.26479C1.1595 12.0659 1.05495 12.0438 0.959989 11.9982C0.865029 11.9526 0.782462 11.8848 0.719287 11.8005C0.630966 11.6814 0.54413 11.4795 0.62874 11.1915L1.36425 8.55678C1.36981 8.53711 1.37649 8.51744 1.38392 8.49814C1.38429 8.49631 1.38429 8.49442 1.38392 8.49258C1.37625 8.4733 1.36969 8.4536 1.36425 8.43357L0.627998 5.78211C0.548212 5.49971 0.635419 5.30229 0.722998 5.18613C0.781807 5.1081 0.858106 5.04495 0.945759 5.00177C1.03341 4.95859 1.12998 4.93657 1.22769 4.9375H1.92979C2.30942 4.9375 2.67792 5.10783 2.89464 5.38281L4.17751 6.94326L7.78899 6.88982L5.39767 1.62029C5.35666 1.52991 5.33897 1.4307 5.34621 1.33172C5.35344 1.23274 5.38537 1.13715 5.43908 1.05369C5.49279 0.970233 5.56656 0.901569 5.65364 0.853976C5.74073 0.806383 5.83836 0.781379 5.93761 0.78125H6.93622C7.07554 0.78405 7.21244 0.818197 7.33675 0.881156C7.46106 0.944115 7.56959 1.03427 7.65429 1.14492L12.2948 6.78555L14.4386 6.72914C14.5956 6.72061 15.0305 6.71764 15.1311 6.71764C17.1817 6.71875 18.4064 7.38449 18.4064 8.5C18.4064 8.85106 18.2661 9.50195 17.3276 9.91609C16.7735 10.161 16.0343 10.285 15.1303 10.285C15.0309 10.285 14.5971 10.282 14.4379 10.2735L12.2944 10.2163L7.64241 15.8569C7.55765 15.9671 7.44921 16.0568 7.32511 16.1194C7.20102 16.1821 7.06443 16.216 6.92546 16.2188Z"
                                    fill="#C3A465"
                                  />
                                </svg>
                              </li>
                              <li>
                                {formatDuration(
                                  item?.itineraries?.[0]?.duration
                                )}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="head-bottom">
                          <div className="head-bottom-wrap">
                            <ul>
                              <li className="text-nowrap">
                                <h4 className="m-0">
                                  {moment(
                                    itinerary?.segments?.[0]?.departure?.at
                                  ).format("HH:mm")}
                                </h4>
                                {getCityDataName(
                                  itinerary?.segments?.[0]?.departure?.iataCode
                                )
                                  ? getCityDataName(
                                      itinerary?.segments?.[0]?.departure
                                        ?.iataCode
                                    )
                                  : getCityName(
                                      itinerary?.segments?.[0]?.departure
                                        ?.iataCode
                                    )}
                              </li>
                              <li>
                                <svg
                                  width="226"
                                  height="9"
                                  viewBox="0 0 226 9"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M225.354 4.85355C225.549 4.65829 225.549 4.34171 225.354 4.14645L222.172 0.964466C221.976 0.769204 221.66 0.769204 221.464 0.964466C221.269 1.15973 221.269 1.47631 221.464 1.67157L224.293 4.5L221.464 7.32843C221.269 7.52369 221.269 7.84027 221.464 8.03553C221.66 8.2308 221.976 8.2308 222.172 8.03553L225.354 4.85355ZM0 5H225V4H0V5Z"
                                    fill="black"
                                  />
                                </svg>
                              </li>
                              <li className="text-nowrap">
                                <h4 className="m-0 position-relative width-content pe-sm-2">
                                  {moment(arrival).format("HH:mm")}
                                  {/* {daysDiff > 0 && (
                                    <span className="h6 ms-sm-2 position-absolute top-0 start-100 translate-middle daysDiff">
                                      +{daysDiff}
                                    </span>
                                  )} */}


                                 {totalDaysHours >= 24 && (
                                    <span className="h6 ms-sm-2 position-absolute top-0 start-100 translate-middle daysDiff">
                                      +{totalDaysHours >= 24 ? 1 : totalDaysHours >= 48  ? 2 : totalDaysHours >= 72  ? 3 : totalDaysHours >= 96 ? 4 : totalDaysHours >= 120 ? 5 : totalDaysHours >= 144 ? 6 : 0}
                                    </span>
                                  )}
                                </h4>
                                {getCityDataName(
                                  itinerary?.segments?.[
                                    itinerary?.segments?.length - 1
                                  ].arrival?.iataCode
                                )
                                  ? getCityDataName(
                                      itinerary?.segments?.[
                                        itinerary?.segments?.length - 1
                                      ].arrival?.iataCode
                                    )
                                  : getCityName(
                                      itinerary?.segments?.[
                                        itinerary?.segments?.length - 1
                                      ].arrival?.iataCode
                                    )}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Accordion.Header>
                    {itinerary?.segments?.length > 1 && (
                      <Accordion.Body>
                        {itinerary?.segments?.length > 0 &&
                          itinerary?.segments?.map((info, segmentIndex) => {
                            const departure_info = info?.departure.at;
                            const arrival_info = info?.arrival.at;
                            const nextSegmentIndex = segmentIndex + 1;
                            const hasNextSegment =
                              nextSegmentIndex < itinerary.segments.length;
                            const nextSegmentDeparture = hasNextSegment
                              ? itinerary.segments[nextSegmentIndex]?.departure
                                  .at
                              : null;
                            let timeDifferenceDisplay = null;

                            if (nextSegmentDeparture) {
                              const dayjsArrival = dayjs(arrival_info);
                              const dayjsDeparture =
                                dayjs(nextSegmentDeparture);
                              const diffInMinutes = dayjsDeparture.diff(
                                dayjsArrival,
                                "minutes"
                              );

                              // Calculate hours and remaining minutes
                              const hours = Math.floor(diffInMinutes / 60);
                              const minutes = diffInMinutes % 60;

                              timeDifferenceDisplay = `${hours}h ${minutes}min`;
                            }

                            const [Departure_date, Departure_time] =
                              departure_info.split("T");
                            const [Arrival_date, Arrival_time] =
                              arrival_info.split("T");
                            return (
                              <>
                                {/* <div
                                className="flight-more-details"
                                key={segmentIndex}
                              >
                                <div
                                  className="flight-more-details-top"
                                  style={{ display: "flex" }}
                                >
                                  <div className="flight-more-details-top-left">
                                    <h4>
                                      {getCityDataName(
                                        info?.departure?.iataCode
                                      )
                                        ? getCityDataName(
                                            info?.departure?.iataCode
                                          )
                                        : getCityName(
                                            info?.departure?.iataCode
                                          )}
                                    </h4>
                                  </div>
                                  <div className="flight-more-details-top-center">
                                    <div>
                                      <svg
                                        width="19"
                                        height="19"
                                        viewBox="0 0 19 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M6.92546 17.2188H5.93761C5.83835 17.2187 5.74068 17.1938 5.65354 17.1463C5.56639 17.0988 5.49255 17.0301 5.43877 16.9467C5.38499 16.8633 5.35298 16.7677 5.34567 16.6687C5.33836 16.5697 5.35598 16.4705 5.39692 16.3801L7.78899 11.1013L4.19718 11.0215L2.88722 12.6087C2.63747 12.9226 2.43819 13.0625 1.92979 13.0625H1.26479C1.1595 13.0659 1.05495 13.0438 0.959989 12.9982C0.865029 12.9526 0.782462 12.8848 0.719287 12.8005C0.630966 12.6814 0.54413 12.4795 0.62874 12.1915L1.36425 9.55678C1.36981 9.53711 1.37649 9.51744 1.38392 9.49814C1.38429 9.49631 1.38429 9.49442 1.38392 9.49258C1.37625 9.4733 1.36969 9.4536 1.36425 9.43357L0.627998 6.78211C0.548212 6.49971 0.635419 6.30229 0.722998 6.18613C0.781807 6.1081 0.858106 6.04495 0.945759 6.00177C1.03341 5.95859 1.12998 5.93657 1.22769 5.9375H1.92979C2.30942 5.9375 2.67792 6.10783 2.89464 6.38281L4.17751 7.94326L7.78899 7.88982L5.39767 2.62029C5.35666 2.52991 5.33897 2.4307 5.34621 2.33172C5.35344 2.23274 5.38537 2.13715 5.43908 2.05369C5.49279 1.97023 5.56656 1.90157 5.65364 1.85398C5.74073 1.80638 5.83836 1.78138 5.93761 1.78125H6.93622C7.07554 1.78405 7.21244 1.8182 7.33675 1.88116C7.46106 1.94411 7.56959 2.03427 7.65429 2.14492L12.2948 7.78555L14.4386 7.72914C14.5956 7.72061 15.0305 7.71764 15.1311 7.71764C17.1817 7.71875 18.4064 8.38449 18.4064 9.5C18.4064 9.85106 18.2661 10.502 17.3276 10.9161C16.7735 11.161 16.0343 11.285 15.1303 11.285C15.0309 11.285 14.5971 11.282 14.4379 11.2735L12.2944 11.2163L7.64241 16.8569C7.55765 16.9671 7.44921 17.0568 7.32511 17.1194C7.20102 17.1821 7.06443 17.216 6.92546 17.2188Z"
                                          fill="#C3A465"
                                        />
                                      </svg>
                                      <p>{formatDuration(info?.duration)}</p>
                                    </div>
                                    <svg
                                      width="226"
                                      height="9"
                                      viewBox="0 0 226 9"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M225.354 4.85355C225.549 4.65829 225.549 4.34171 225.354 4.14645L222.172 0.964466C221.976 0.769204 221.66 0.769204 221.464 0.964466C221.269 1.15973 221.269 1.47631 221.464 1.67157L224.293 4.5L221.464 7.32843C221.269 7.52369 221.269 7.84027 221.464 8.03553C221.66 8.2308 221.976 8.2308 222.172 8.03553L225.354 4.85355ZM0 5H225V4H0V5Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </div>
                                  <div className="flight-more-details-top-right">
                                    <h4>
                                      {getCityDataName(info?.arrival?.iataCode)
                                        ? getCityDataName(
                                            info?.arrival?.iataCode
                                          )
                                        : getCityName(info?.arrival?.iataCode)}
                                    </h4>
                                  </div>
                                </div>
                                <div
                                  className="flight-more-details-bottom"
                                  style={{ display: "flex" }}
                                >
                                  <div className="flight-more-details-bottom-left">
                                    <div className="aaaa">
                                      <p>
                                        Departure: <span>{Departure_date}</span>
                                      </p>
                                    </div>
                                    <div className="bbbb">
                                      <div>
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g clip-path="url(#clip0_83_1746)">
                                            <path
                                              d="M7.99992 1.33325C11.6819 1.33325 14.6666 4.31792 14.6666 7.99992C14.6666 11.6819 11.6819 14.6666 7.99992 14.6666C4.31792 14.6666 1.33325 11.6819 1.33325 7.99992C1.33325 4.31792 4.31792 1.33325 7.99992 1.33325ZM7.99992 2.66659C6.58543 2.66659 5.22888 3.22849 4.22868 4.22868C3.22849 5.22888 2.66659 6.58543 2.66659 7.99992C2.66659 9.41441 3.22849 10.771 4.22868 11.7712C5.22888 12.7713 6.58543 13.3333 7.99992 13.3333C9.41441 13.3333 10.771 12.7713 11.7712 11.7712C12.7713 10.771 13.3333 9.41441 13.3333 7.99992C13.3333 6.58543 12.7713 5.22888 11.7712 4.22868C10.771 3.22849 9.41441 2.66659 7.99992 2.66659ZM7.99992 3.99992C8.16321 3.99994 8.32081 4.05989 8.44283 4.1684C8.56486 4.2769 8.64281 4.42642 8.66192 4.58859L8.66658 4.66658V7.72392L10.4713 9.52858C10.5908 9.64856 10.6602 9.80954 10.6654 9.97884C10.6706 10.1481 10.6111 10.3131 10.4991 10.4401C10.3871 10.5671 10.2309 10.6468 10.0623 10.6629C9.89365 10.6789 9.72525 10.6302 9.59125 10.5266L9.52858 10.4713L7.52858 8.47125C7.42497 8.36755 7.35843 8.23259 7.33925 8.08725L7.33325 7.99992V4.66658C7.33325 4.48977 7.40349 4.3202 7.52851 4.19518C7.65354 4.07016 7.82311 3.99992 7.99992 3.99992Z"
                                              fill="#C3A465"
                                            />
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_83_1746">
                                              <rect
                                                width="16"
                                                height="16"
                                                fill="white"
                                              />
                                            </clipPath>
                                          </defs>
                                        </svg>
                                      </div>
                                      <p>{Departure_time}</p>
                                    </div>
                                  </div>
                                  <div className="flight-more-details-bottom-right">
                                    <div className="aaaa">
                                      <p>
                                        Arrival: <span>{Arrival_date}</span>
                                      </p>
                                    </div>
                                    <div className="bbbb">
                                      <div>
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g clip-path="url(#clip0_83_1746)">
                                            <path
                                              d="M7.99992 1.33325C11.6819 1.33325 14.6666 4.31792 14.6666 7.99992C14.6666 11.6819 11.6819 14.6666 7.99992 14.6666C4.31792 14.6666 1.33325 11.6819 1.33325 7.99992C1.33325 4.31792 4.31792 1.33325 7.99992 1.33325ZM7.99992 2.66659C6.58543 2.66659 5.22888 3.22849 4.22868 4.22868C3.22849 5.22888 2.66659 6.58543 2.66659 7.99992C2.66659 9.41441 3.22849 10.771 4.22868 11.7712C5.22888 12.7713 6.58543 13.3333 7.99992 13.3333C9.41441 13.3333 10.771 12.7713 11.7712 11.7712C12.7713 10.771 13.3333 9.41441 13.3333 7.99992C13.3333 6.58543 12.7713 5.22888 11.7712 4.22868C10.771 3.22849 9.41441 2.66659 7.99992 2.66659ZM7.99992 3.99992C8.16321 3.99994 8.32081 4.05989 8.44283 4.1684C8.56486 4.2769 8.64281 4.42642 8.66192 4.58859L8.66658 4.66658V7.72392L10.4713 9.52858C10.5908 9.64856 10.6602 9.80954 10.6654 9.97884C10.6706 10.1481 10.6111 10.3131 10.4991 10.4401C10.3871 10.5671 10.2309 10.6468 10.0623 10.6629C9.89365 10.6789 9.72525 10.6302 9.59125 10.5266L9.52858 10.4713L7.52858 8.47125C7.42497 8.36755 7.35843 8.23259 7.33925 8.08725L7.33325 7.99992V4.66658C7.33325 4.48977 7.40349 4.3202 7.52851 4.19518C7.65354 4.07016 7.82311 3.99992 7.99992 3.99992Z"
                                              fill="#C3A465"
                                            />
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_83_1746">
                                              <rect
                                                width="16"
                                                height="16"
                                                fill="white"
                                              />
                                            </clipPath>
                                          </defs>
                                        </svg>
                                      </div>
                                      <p>{Arrival_time}</p>
                                    </div>
                                  </div>
                                </div>
                                {timeDifferenceDisplay && (
                                  <p className="flight-difference">
                                    Flight Difference: {timeDifferenceDisplay}
                                  </p>
                                )}
                              </div> */}
                                <div className="d-flex flex-column align-items-start">
                                  <div className="d-flex align-items-center firstCircle">
                                    <div className="circle"></div>
                                    <h5 className="m-0 p-0 ms-2 fw-normal">
                                      {moment(
                                        Departure_time,
                                        "HH:mm:ss"
                                      ).format("HH:mm")}

                                      <span className="fw-medium ms-2 text-gold1">
                                        {getCityDataName(
                                          info?.departure?.iataCode
                                        )
                                          ? getCityDataName(
                                              info?.departure?.iataCode
                                            )
                                          : getCityName(
                                              info?.departure?.iataCode
                                            )}
                                      </span>
                                      <span className="ms-2">
                                        {/* {Departure_date} */}
                                      </span>
                                    </h5>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <div className="vertiLine"></div>
                                    <p className="h6 m-0 text-secondary fw-normal ms-3">
                                      {formatDuration(info?.duration)}
                                    </p>
                                  </div>
                                  <div className="d-flex align-items-center lastCircle">
                                    <div className="circle"></div>
                                    <h5 className="m-0 p-0 ms-2 fw-normal">
                                      {moment(Arrival_time, "HH:mm:ss").format(
                                        "HH:mm"
                                      )}
                                      <span className="fw-medium ms-2 text-gold1">
                                        {" "}
                                        {getCityDataName(
                                          info?.arrival?.iataCode
                                        )
                                          ? getCityDataName(
                                              info?.arrival?.iataCode
                                            )
                                          : getCityName(
                                              info?.arrival?.iataCode
                                            )}
                                      </span>
                                      <span className="ms-2">
                                        {/* {Arrival_date} */}
                                      </span>
                                    </h5>
                                  </div>
                                </div>
                                {timeDifferenceDisplay && (
                                  <p className="flight-difference">
                                    Flight Difference: {timeDifferenceDisplay}
                                  </p>
                                )}
                              </>
                            );
                          })}
                      </Accordion.Body>
                    )}
                  </div>

                  <div className="right">
                    {itineraryIndex === 0 && (
                      <ul>
                        <li>Start From</li>
                        <li className="d-flex align-items-center fs-4 text-nowrap me-2">
                          SEK {item?.price?.grandTotal}
                        </li>


                        {/* { item?.itineraries?.[0]?.duration == minDuration && Number(item?.price?.grandTotal) === minimumPrice && (
                            <h5 className="mt-5">BEST</h5>
                          )
                        } */}
                        
                        {item?.id === '1' && 
                          <h5 className="mt-5">CHEAPEST</h5>
                           }
                        
                        {/* {Number(item?.price?.grandTotal) === minimumPrice && 
                          <h5 className="mt-5">CHEAPEST</h5>
                           }
                         */}
                        {/* { item?.itineraries?.[0]?.duration == minDuration && (
                            <h5 className="mt-5">FASTEST</h5>
                          )
                        } */}

                      </ul>
                    )}
                  </div>
                </div>

                <div className="top"></div>

                {/* <Accordion.Header className={activeKey === itineraryIndex.toString() ? "custom-active-accordion" : ""}
                  onClick={() => handleAccordionClick(itineraryIndex.toString())}>
                  <div key={itineraryIndex} className="">
                    <div className="d-flex flex-column flex-md-row align-items-center">
                      <div className="d-flex flight__card  align-items-center p-lg-1 justify-content-md-between w-100">
                        <Logo name={getAirlineName(itinerary?.segments?.[0]?.carrierCode)} code={itinerary?.segments?.[0]?.carrierCode} />
                        <div className="d-flex align-items-center justify-content-center flex-column w-100 ps-1">
                          <div className="w-100">
                            <p className="d-md-none text-center m-0 text-dark-red fw-semibold">
                              {formatDuration(item?.itineraries?.[0]?.duration)}
                            </p>
                          </div>

                          <div className="d-flex w-100 align-items-center justify-content-between">
                            <h3 className="m-0 gradient__text">
                              {getCityDataName(itinerary?.segments?.[0]?.departure?.iataCode)
                                ? getCityDataName(itinerary?.segments?.[0]?.departure?.iataCode)
                                : getCityName(itinerary?.segments?.[0]?.departure?.iataCode)}
                            </h3>
                            <div className="dotted_Lines_white bg-purples position-relative  mx-2">
                              <p className="position-absolute top-50 start-50 translate-middle pb-4 d-none d-md-block  text-dark-red fw-semibold">

                                {formatDuration(
                                  item?.itineraries?.[0]?.duration
                                )}
                              </p>
                              <FaPlane className="text-dark-red position-absolute top-50 start-100 translate-middle fs-3 planeIcon" />
                            </div>
                            <FaArrowRightLong className="text-dark-red d-sm-none" />
                            <h4 className="m-0 gradient__text">
                              {getCityDataName(
                                itinerary?.segments?.[
                                  itinerary?.segments?.length - 1
                                ].arrival?.iataCode
                              )
                                ? getCityDataName(
                                  itinerary?.segments?.[
                                    itinerary?.segments?.length - 1
                                  ].arrival?.iataCode
                                )
                                : getCityName(
                                  itinerary?.segments?.[
                                    itinerary?.segments?.length - 1
                                  ].arrival?.iataCode
                                )}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Header> */}

                {/* <Accordion.Body>
                  {itinerary?.segments?.length > 0 &&
                    itinerary?.segments?.map((info, segmentIndex) => {
                      const departure_info = info?.departure.at;
                      const arrival_info = info?.arrival.at;

                      const nextSegmentIndex = segmentIndex + 1;
                      const hasNextSegment =
                        nextSegmentIndex < itinerary.segments.length;

                      const nextSegmentDeparture = hasNextSegment
                        ? itinerary.segments[nextSegmentIndex]?.departure.at
                        : null;

                      let timeDifferenceDisplay = null;

                      if (nextSegmentDeparture) {
                        const dayjsArrival = dayjs(arrival_info);
                        const dayjsDeparture = dayjs(nextSegmentDeparture);
                        const diffInMinutes = dayjsDeparture.diff(
                          dayjsArrival,
                          "minutes"
                        );

                        // Calculate hours and remaining minutes
                        const hours = Math.floor(diffInMinutes / 60);
                        const minutes = diffInMinutes % 60;

                        timeDifferenceDisplay = `${hours}h ${minutes}min`;
                      }

                      const [Departure_date, Departure_time] =
                        departure_info.split("T");
                      const [Arrival_date, Arrival_time] =
                        arrival_info.split("T");
                      return (
                        <>
                          <div
                            key={segmentIndex}
                            className=" rounded-2 w-100 mb-md-4 mt-1"
                          >
                            <div className="d-flex flex-column flex-md-row align-items-center">
                              <div
                                key={segmentIndex}
                                className="d-flex flight__card  align-items-center p-2  w-100"
                              >
                                <Logo
                                  name={getAirlineName(info?.carrierCode)}
                                  code={info?.carrierCode}
                                />
                                <div className="d-flex w-100 justify-content-between ps-1">
                                  <div className="d-flex flex-column">
                                    <h4 className="m-0 gradient__text">
                                      {getCityDataName(
                                        info?.departure?.iataCode
                                      )
                                        ? getCityDataName(
                                          info?.departure?.iataCode
                                        )
                                        : getCityName(
                                          info?.departure?.iataCode
                                        )}
                                    </h4>
                                    <div className="">
                                      <p className="h6 m-0 fw-normal">Departure</p>
                                      <p className="m-0 d-flex align-items-center">
                                        <FaCalendarDays className="fs-6 me-1 text-dark-red" />{" "}
                                        {Departure_date}
                                      </p>
                                      <p className="m-0 d-flex align-items-center">
                                        <FaRegClock className="fs-6 me-1 text-dark-red" />{" "}
                                        {Departure_time}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="dotted_Lines_white mt-14 bg-purples position-relative mx-2">
                                    <p className="position-absolute top-50 start-50 translate-middle pb-4 d-none d-md-block  text-dark-red fw-semibold">
                                      {formatDuration(info?.duration)}
                                    </p>
                                    <FaPlane className="text-dark-red position-absolute top-50 start-100 translate-middle fs-3 planeIcon" />
                                  </div>

                                  <FaArrowRightLong className="text-dark-red d-sm-none" />

                                  <div className="d-flex flex-column">
                                    <h4 className="m-0 gradient__text">
                                      {getCityDataName(info?.arrival?.iataCode)
                                        ? getCityDataName(
                                          info?.arrival?.iataCode
                                        )
                                        : getCityName(info?.arrival?.iataCode)}
                                    </h4>
                                    <div className="">
                                      <p className="h6 m-0 fw-normal">Arrival</p>
                                      <p className="m-0 d-flex">
                                        <FaCalendarDays className="fs-6 me-1 text-dark-red" />
                                        {Arrival_date}
                                      </p>
                                      <p className="m-0 pb-1 d-flex flex-nowrap align-items-center">
                                        <FaRegClock className="fs-6 me-1 text-dark-red" />{" "}
                                        {Arrival_time}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {timeDifferenceDisplay && (
                              <p className="h6 m-0 text-center gradient__text">
                                Flight Difference: {timeDifferenceDisplay}
                              </p>
                            )}
                          </div>
                        </>
                      );
                    })}
                </Accordion.Body> */}
              </Accordion.Item>
            </div>
          );
        })}
      </Accordion>
    </>
  );
};

export default FlightItineraries;
