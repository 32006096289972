// import React, { useState } from "react";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
// import { FaPlane } from "react-icons/fa6";
// import CheckoutForm from "../CheckoutForm";
// import Modal from "react-bootstrap/Modal";
// // import planeImg from "../assets/plane_01.jpg";
// import planeImg from "../assets/goldenJet.jpg";
// import { cities } from "components/common/cities";
// const promise = loadStripe(
//   "pk_test_51NaEPKCnrSPz98Sf7R0zunmwmHzCb3hh41G1kDpujKJMTCadqKAyxxTnR197JAOYNVoYkHzfBK14GVLrdzmQc1Yn00Q3KAnxBg"
// );

// const PaymentModal = ({
//   coupon,
//   showModal,
//   handleToggleModal,
//   item,
//   flight_facilities,
//   total,
//   formattedGuests,
//   setShowTicketModal,
//   setTicektData,
// }) => {
//   // destructure
//   let departureCode =
//     item?.itineraries?.[0]?.segments?.[0]?.departure?.iataCode;
//   let arrivalCode =
//     item?.itineraries?.[0]?.segments[
//       item?.itineraries?.[0]?.segments?.length - 1
//     ]?.arrival?.iataCode;

//   const getCityDataName = (iataCode) => {
//     const [cityName] = cities.filter((c) => c.iata_code === iataCode);
//     return cityName ? cityName?.city : "";
//   };
//   return (
//     <Modal
//       show={showModal}
//       size="lg"
//       onHide={handleToggleModal}
//       backdrop="static"
//       keyboard={false}
//       centered
//     >
//       <Modal.Header className="bg-gold border-0 " closeButton></Modal.Header>
//       <Modal.Body className="p-0 modal_pay">
//         <div class="position-relative">
//           <img src={planeImg} alt="" className="img-fluid payModalplane" />
//           <h4 className="ms-2 position-absolute top-50 start-50 translate-middle text-purple">
//             Fly with FlyProView
//           </h4>
//         </div>
//         <div className="row align-items-center p-3">
//           <div className="col-md-6 my-3">
//             <div className="d-flex mb-3 align-items-center justify-content-start">
//               <h4 className="m-0">{getCityDataName(departureCode)}</h4>
//               <FaPlane className="mx-4 text-white fs-3" />
//               <h4 className="m-0">{getCityDataName(arrivalCode)}</h4>
//             </div>
//             <div class="d-flex flex-wrap gap-3 mt-3">
//               <div class="">
//                 <p class="text-white m-0 text-nowrap ">Flight Class</p>
//                 <h5 class="text-white fw-normal m-0">
//                   {
//                     item?.travelerPricings?.[0]?.fareDetailsBySegment?.[0]
//                       ?.cabin
//                   }
//                 </h5>
//               </div>
//               <div class="">
//                 <p class="text-white m-0">Price</p>
//                 <h5 class="text-white fw-normal m-0 text-nowrap">
//                   SEK {total}
//                 </h5>
//               </div>
//             </div>
//           </div>
//           <div className="col-lg-6 my-3">
//             <Elements stripe={promise}>
//               <CheckoutForm
//                 coupon={coupon}
//                 total={total}
//                 item={item}
//                 flight_facilities={flight_facilities}
//                 handlePaymentModal={handleToggleModal}
//                 formattedGuests={formattedGuests}
//                 setTicektData={setTicektData}
//                 setShowTicketModal={setShowTicketModal}
//               />
//             </Elements>
//           </div>
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export default PaymentModal;

import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { FaPlane } from "react-icons/fa6";
import CheckoutForm from "../CheckoutForm";
import Modal from "react-bootstrap/Modal";
// import planeImg from "../assets/plane_01.jpg";
import planeImg from "../assets/goldenJet.jpg";
import { cities } from "components/common/cities";
const promise = loadStripe(
  "pk_test_51NaEPKCnrSPz98Sf7R0zunmwmHzCb3hh41G1kDpujKJMTCadqKAyxxTnR197JAOYNVoYkHzfBK14GVLrdzmQc1Yn00Q3KAnxBg"
  // "pk_test_51NaEPKCnrSPz98Sf7R0zunmwmHzCb3hh41G1kDpujKJMTCadqKAyxxTnR197JAOYNVoYkHzfBK14GVLrdzmQc1Yn00Q3KAnxBg"
  // "pk_test_51NaEPKCnrSPz98Sf7R0zunmwmHzCb3hh41G1kDpujKJMTCadqKAyxxTnR197JAOYNVoYkHzfBK14GVLrdzmQc1Yn00Q3KAnxBg"
// "pk_test_51NaGD5BJdBka6qy38zlOuQDC0sZ1lpzrzcsGxnI652eCr2UkqNPwdENQzhIPa6OTYSuvl7NsEOFUjwKTBX7o7heC00DcWCewyw"
);

const PaymentModal = ({
  coupon,
  showModal,
  handleToggleModal,
  item,
  flight_facilities,
  total,
  formattedGuests,
  setShowTicketModal,
  setTicektData,
}) => {
  // destructure
  let departureCode =
    item?.itineraries?.[0]?.segments?.[0]?.departure?.iataCode;
  let arrivalCode =
    item?.itineraries?.[0]?.segments[
      item?.itineraries?.[0]?.segments?.length - 1
    ]?.arrival?.iataCode;

  const getCityDataName = (iataCode) => {
    const [cityName] = cities.filter((c) => c.iata_code === iataCode);
    return cityName ? cityName?.city : "";
  };
  return (
    <Modal
      show={showModal}
      size="lg"
      onHide={handleToggleModal}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header className="bg-gold border-0 " closeButton></Modal.Header>
      <Modal.Body className="p-0 modal_pay">
        <div class="position-relative">
          <img src={planeImg} alt="" className="img-fluid payModalplane" />
          <h4 className="ms-2 position-absolute top-50 start-50 translate-middle text-purple">
            Fly with FlyProView
          </h4>
        </div>
        <div className="row align-items-center p-3">
          <div className="col-md-6 my-3">
            <div className="d-flex mb-3 align-items-center justify-content-start">
              <h4 className="m-0">{getCityDataName(departureCode)}</h4>
              <FaPlane className="mx-4 text-white fs-3" />
              <h4 className="m-0">{getCityDataName(arrivalCode)}</h4>
            </div>
            <div class="d-flex flex-wrap gap-3 mt-3">
              <div class="">
                <p class="text-white m-0 text-nowrap ">Flight Class</p>
                <h5 class="text-white fw-normal m-0">
                  {
                    item?.travelerPricings?.[0]?.fareDetailsBySegment?.[0]
                      ?.cabin
                  }
                </h5>
              </div>
              <div class="">
                <p class="text-white m-0">Price</p>
                <h5 class="text-white fw-normal m-0 text-nowrap">
                  SEK {total}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-lg-6 my-3">
            <Elements stripe={promise}>
              <CheckoutForm
                coupon={coupon}
                total={total}
                item={item}
                flight_facilities={flight_facilities}
                handlePaymentModal={handleToggleModal}
                formattedGuests={formattedGuests}
                setTicektData={setTicektData}
                setShowTicketModal={setShowTicketModal}
              />
            </Elements>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentModal;