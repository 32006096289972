import React, { useRef, useState } from "react";
import FocusTrap from "focus-trap-react";
import { DayPicker } from "react-day-picker";
import { usePopper } from "react-popper";
import "react-date-picker/dist/DatePicker.css";
import "react-day-picker/dist/style.css";
import moment from "moment";

export default function TravelerDateInput({
  selectedDate,
  handleInputChange,
  index,
  type,
  departureDate,
  traveler
}) {
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const popperRef = useRef(null);
  const buttonRef = useRef(null);
  const [popperElement, setPopperElement] = useState(null);
  const popper = usePopper(popperRef.current, popperElement, {
    placement: "bottom-start",
  });


  const closePopper = () => {
    setIsPopperOpen(false);
    buttonRef?.current?.focus();
  };

  const handleButtonClick = () => {
    setIsPopperOpen(true);
  };


  const handleDaySelect = (date) => {
    if (date) {
      handleInputChange(index, type, date);
    }
    closePopper();
  };


  const today = moment();
  const departure = moment(departureDate);

  const isDateDisabled = (date) => {
    const selected = moment(date);
    if (type === "expiryDate") {
      return !selected.isAfter(departure);
    }

    if (type === "issuanceDate") {
      return selected.isAfter(today);
    }

    if (type === "dateOfBirth") {
      if (traveler === "ADULT") {
        return !selected.isBefore(today);
      }
      if (traveler === "HELD_INFANT") {
        const twoYearsAgo = departure.clone().subtract(2, "years");
        return !selected.isBetween(twoYearsAgo, today, "day", "[]");
      }
      if (traveler === "CHILD") {
        const fifteenYearsAgo = departure.clone().subtract(15, "years");
        return !selected.isBetween(fifteenYearsAgo, today, "day", "[]");
      }
    }
    return false;
  };

  const getDefaultMonth = () => {
    if (selectedDate) {
      return moment(selectedDate).toDate();
    }
  
    if (type === "expiryDate") {
      return departure.toDate();
    }
    if (type === "issuanceDate") {
      return today.toDate();
    }
    if (type === "dateOfBirth") {
      if (traveler === "ADULT") {
        return today.clone().subtract(18, "years").toDate();
      }
      if (traveler === "HELD_INFANT") {
        return departure.clone().subtract(1, "year").toDate();
      }
      if (traveler === "CHILD") {
        return departure.clone().subtract(7, "years").toDate();
      }
    }
    return new Date();
  };
  

  console.log(departureDate, "departureDate");
  return (
    <div className="date-field">
      <div ref={popperRef}>
        <input
          size={12}
          type="text"
          className="pt-0 ms-2 text-dark"
          disabled
          placeholder="select date"
          value={selectedDate && moment(selectedDate).format("DD-MM-YYYY")}
        />

        <div className="icon" ref={buttonRef} onClick={handleButtonClick}>
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.75 3.07617H19.25V1.82617C19.25 1.49465 19.1183 1.17671 18.8839 0.942288C18.6495 0.707868 18.3315 0.576172 18 0.576172C17.6685 0.576172 17.3505 0.707868 17.1161 0.942288C16.8817 1.17671 16.75 1.49465 16.75 1.82617V3.07617H9.25V1.82617C9.25 1.49465 9.1183 1.17671 8.88388 0.942288C8.64946 0.707868 8.33152 0.576172 8 0.576172C7.66848 0.576172 7.35054 0.707868 7.11612 0.942288C6.8817 1.17671 6.75 1.49465 6.75 1.82617V3.07617H4.25C3.25544 3.07617 2.30161 3.47126 1.59835 4.17452C0.895088 4.87778 0.5 5.83161 0.5 6.82617V21.8262C0.5 22.8207 0.895088 23.7746 1.59835 24.4778C2.30161 25.1811 3.25544 25.5762 4.25 25.5762H21.75C22.7446 25.5762 23.6984 25.1811 24.4017 24.4778C25.1049 23.7746 25.5 22.8207 25.5 21.8262V6.82617C25.5 5.83161 25.1049 4.87778 24.4017 4.17452C23.6984 3.47126 22.7446 3.07617 21.75 3.07617ZM23 21.8262C23 22.1577 22.8683 22.4756 22.6339 22.7101C22.3995 22.9445 22.0815 23.0762 21.75 23.0762H4.25C3.91848 23.0762 3.60054 22.9445 3.36612 22.7101C3.1317 22.4756 3 22.1577 3 21.8262V13.0762H23V21.8262ZM23 10.5762H3V6.82617C3 6.49465 3.1317 6.17671 3.36612 5.94229C3.60054 5.70787 3.91848 5.57617 4.25 5.57617H6.75V6.82617C6.75 7.15769 6.8817 7.47563 7.11612 7.71006C7.35054 7.94448 7.66848 8.07617 8 8.07617C8.33152 8.07617 8.64946 7.94448 8.88388 7.71006C9.1183 7.47563 9.25 7.15769 9.25 6.82617V5.57617H16.75V6.82617C16.75 7.15769 16.8817 7.47563 17.1161 7.71006C17.3505 7.94448 17.6685 8.07617 18 8.07617C18.3315 8.07617 18.6495 7.94448 18.8839 7.71006C19.1183 7.47563 19.25 7.15769 19.25 6.82617V5.57617H21.75C22.0815 5.57617 22.3995 5.70787 22.6339 5.94229C22.8683 6.17671 23 6.49465 23 6.82617V10.5762Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
            fallbackFocus: buttonRef.current || undefined,
          }}
        >
          <div
            tabIndex={-1}
            style={popper.styles.popper}
            className="dialog-sheet bg-white shadow rounded-2 z-index_1000"
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
            aria-label="DayPicker calendar"
          >
            <DayPicker
              initialFocus={isPopperOpen}
              mode="single"
              selected={
                selectedDate ||
                (type === "expiryDate" && departureDate
                  ? moment(departureDate, "YYYY-MM-DD").toDate()
                  : undefined)
              }
              // selected={
              //   selectedDate ||
              //   (type === "expiryDate" && departureDate
              //     ? moment(departureDate, "YYYY-MM-DD").toDate()
              //     : undefined)
              // }
              onSelect={handleDaySelect}
              disabled={isDateDisabled}
              captionLayout="dropdown-buttons"
              fromYear={type === "expiryDate" ? moment().year() : 1950}
              defaultMonth={getDefaultMonth()}
              toYear={type === "expiryDate" ? 2050 : moment().year()}
            />
          </div>
        </FocusTrap>
      )}
    </div>
  );
}
