import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import fr from "react-phone-input-2/lang/es.json";
import "react-phone-input-2/lib/style.css";

export const TravelerPhoneInput = ({
  handleInputChange,
  index,
  type,
  value,
}) => {
  const [isValid, setIsValid] = useState(true);
  
  console.log(isValid, "isValid");
  return (
    <div className="date-field-phone">
      <PhoneInput
        localization={fr}
        value={value}
        country={"se"}
        regions={'europe'}
        placeholder="Phone Number"
        className="mt-2 w-100 px-0"
        onChange={(phone) => handleInputChange(index,"phone", phone)}
        isValid={(value, country) => {
          if (value.match(/12345/)) {
            return "Invalid value: " + value + ", " + country.name;
          } else if (value.match(/1234/)) {
            console.log("valid");
            return false;
          } else {
            console.log("valid");
            return true;
          }
        }}
      />
    </div>
  );
};
