import React, { useState, useEffect, useRef } from "react";

import { TiMinus, TiPlus } from "react-icons/ti";
export const PassangerDropDown = ({
  data,
  adults,
  childrens,
  setChildrens,
  setAdults,
  setInfant,
  infant
}) => {
  const [show, setShow] = useState(false);
  const dropdownRef = useRef(null);
// close the dropdown  when clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const toggle = () => {
    setShow(!show);
  };

  const handlePassangerDropDownClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div className="custom-dd-one" onClick={toggle} ref={dropdownRef}>
      <div className="custom-dd-one-wrap">
        <ul >
          <li
            style={{ position: "relative" }}
            dangerouslySetInnerHTML={{ __html: data.icon }}
          ></li>
          <li>{adults +" ADT, "+childrens +" CHD, "+infant+" INF"}</li>
          <li>
            <svg
              width="20"
              height="20"
              className="custom-arrow"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 7.5L10 12.5L15 7.5"
                stroke="#232339"
                strokeWidth="2"
              />
            </svg>
          </li>
        </ul>
      </div>
      {show && (
        <div className="options shadow">
          <ul id="passangerDropDown" onClick={handlePassangerDropDownClick}>
            <li className="bg-white text-dark">
              <div className="d-flex justify-content-between ">
                <h6 className="m-0 user-select-none ">Adult</h6>
                <div className="d-flex align-items-center gap-2">
                  <TiMinus className="text--golden" onClick={()=>{
                    if(adults>1){
                      if(infant === adults){
                        setAdults(adults-1)
                        setInfant(infant-1)
                      }else{
                        setAdults(adults-1)
                      }
                    }
                  }} />
                  <h6 className="m-0 user-select-none fw-semibold">{adults}</h6>
                  <TiPlus className="text--golden"
                  onClick={()=>{
                    if(adults<5){
                      setAdults(adults+1)
                     
                    }
                  }}
                  />
                </div>
              </div>
            </li>
            <li className="bg-white text-dark">
              <div className="d-flex justify-content-between ">
                <h6 className="m-0 user-select-none ">Children</h6>
                <div className="d-flex align-items-center gap-2">
                  <TiMinus className="text--golden"
                   onClick={()=>{
                    if(childrens>0){
                      setChildrens(childrens-1)
                    }
                  }}
                  />
                  <h6 className="m-0 user-select-none fw-semibold">{childrens}</h6>
                  <TiPlus className="text--golden"
                   onClick={()=>{
                    if(childrens<3){
                      setChildrens(childrens+1)
                    }
                  }}
                  />
                </div>
              </div>
            </li>
            <li className="bg-white text-dark">
              <div className="d-flex justify-content-between ">
                <h6 className="m-0 user-select-none ">Infant</h6>
                <div className="d-flex align-items-center gap-2">
                  <TiMinus className="text--golden"
                   onClick={()=>{
                    if(infant>0){
                      setInfant(infant-1)
                    }
                  }}
                  />
                  <h6 className="m-0 user-select-none fw-semibold">{infant}</h6>
                  <TiPlus className="text--golden"
                   onClick={()=>{
                    if(infant < adults){
                      setInfant(infant+1)
                    }
                  }}
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
