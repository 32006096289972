import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import PolicyEnglish from "./terms_condition_content/PolicyEnglish";
import EnglishAgreement from "./terms_condition_content/EnglishAgreement";
import PolicySweedish from "./terms_condition_content/PolicySweedish";
import AgreementSweedish from "./terms_condition_content/AgreementSweedish";

const TermsConditionComp = ({ language }) => {
  const [contentType, setContentType] = useState("policy");

  return (
    <>
      <Nav
        variant="pills"
        activeKey={contentType}
        onSelect={(selectedKey) => setContentType(selectedKey)}
        className="d-flex justify-content-center my-5"
      >
        <Nav.Item className="language_option">
          <Nav.Link eventKey="policy">Privacy Policy</Nav.Link>
        </Nav.Item>
        <Nav.Item className="language_option">
          <Nav.Link eventKey="agreement">User Agreement</Nav.Link>
        </Nav.Item>
      </Nav>
      {(contentType === "policy" && language === "english") && <PolicyEnglish />}
      {(contentType === "policy" && language === "swedish") && <PolicySweedish />}
      {(contentType === "agreement" && language === "english") && <EnglishAgreement />}
      {(contentType === "agreement" && language === "swedish") && <AgreementSweedish />}
    </>
  );
};

export default TermsConditionComp;
