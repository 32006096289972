import React from "react";
import flightLaunch from "./assets/flight1.jpg";
// import flightLaunch from "./assets/fligthLaunch.jpg";
import flightTicekts from "./assets/girlwithtickets.png";
import { GiCheckMark } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
const AboutUsComp = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };
  return (
    <>
      <div className="container my-4">
        <div className="row align-items-center">
          <div className="col-lg-6 my-3">
            <h4 className="fw-bold fst-italic ">Why Choose Us</h4>
            <h5 className="fw-normal lh-base">
              FlyProview offers more than just flight bookings; we provide a
              seamless travel experience tailored to your needs. With our
              user-friendly platform, extensive flight options, and dedicated
              customer support, choosing us means choosing reliability,
              convenience, and expertise. Whether you're a frequent flyer or
              planning your dream vacation, trust FlyProview to elevate your
              journey from start to finish.
            </h5>
          </div>
          <div className="col-lg-6 my-3">
            <img src={flightLaunch} className="img-fluid rounded-3" alt="" />
          </div>
        </div>
      </div>
      <div className="bgPicnic d-flex align-items-center">
        <div className="container">
          <h1 className="text-center text-white fw-semibold">
            Book Your Adventure Now!
          </h1>
          <h5 className="text-center text-white fw-normal mt-3">
            Discover World's Hidden Treasures
          </h5>

          <button
            className="discoverNowBtn text-decoration-none"
            onClick={handleClick}
          >
            Discover Now
          </button>
        </div>
      </div>
      <div className="container my-4 mt-5">
        <h2 className="text-center fw-semibold fst-italic">
          Your Gateway to Seamless Travel Experiences
        </h2>
        <div className="col-lg-9 mt-3 mb-4 mx-auto">
          <h6 className="fw-normal lh-base text-center">
            Embark on a journey like no other with FlyProview: Where seamless
            travel experiences await. Let us be your guide to unforgettable
            adventures, where every moment is crafted with care and expertise.
          </h6>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 my-3">
            <div className="aboutCard">
              <div className="noCircle">1</div>
              <h5 className="fw-semibold">Effortless Booking Experience</h5>
              <h6 className="fw-normal lh-base">
                With FlyProview, booking your flights is as easy as a breeze.
                Navigate through our user-friendly platform with seamless
                efficiency, ensuring your travel plans take off without a hitch.
              </h6>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 my-3">
            <div className="aboutCard">
              <div className="noCircle">2</div>
              <h5 className="fw-semibold">Tailored Travel Solutions</h5>
              <h6 className="fw-normal lh-base">
                Experience travel reimagined with FlyProview's bespoke
                solutions. From personalized itineraries to exclusive deals,
                embark on journeys crafted just for you, unforgettable
                adventures every step of the way.
              </h6>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 my-3">
            <div className="aboutCard">
              <div className="noCircle">3</div>
              <h5 className="fw-semibold">Exceptional Customer Support</h5>
              <h6 className="fw-normal lh-base">
                At FlyProview, your satisfaction is our priority. Our dedicated
                team of travel experts is at your service around the clock,
                ready to assist with any query or concern, ensuring your journey
                is smooth, seamless, and stress-free.
              </h6>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 my-3">
            <div className="aboutCard">
              <div className="noCircle">4</div>
              <h5 className="fw-semibold">Unrivaled Flight Selection</h5>
              <h6 className="fw-normal lh-base">
                Discover the world with FlyProview's extensive array of flight
                options. From budget-friendly fares to premium experiences, we
                offer a diverse range of choices to suit every traveler's
                preferences, ensuring you find the perfect flight for your next
                adventure.
              </h6>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 my-3">
            <div className="aboutCard">
              <div className="noCircle">5</div>
              <h5 className="fw-semibold">Seamless Travel Planning</h5>
              <h6 className="fw-normal lh-base">
                Let FlyProview be your ultimate travel companion, simplifying
                every aspect of your journey. With intuitive tools and
                comprehensive resources, we streamline the planning process,
                allowing you to focus on creating unforgettable memories
                wherever you go.
              </h6>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 my-3">
            <div className="aboutCard">
              <div className="noCircle">6</div>
              <h5 className="fw-semibold">Trusted Travel Partner</h5>
              <h6 className="fw-normal lh-base">
                Choose FlyProview as your trusted ally in the skies. With a
                reputation built on reliability, integrity, and professionalism,
                we are committed to providing you with a travel experience that
                exceeds expectations, ensuring every trip with us is nothing
                short of exceptional.
              </h6>
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-4">
          <div className="col-lg-6 my-3">
            <h4 className="fw-bold fst-italic ">Mission & Values</h4>
            <h5 className="fw-normal lh-base">
              At FlyProview, our mission is to redefine the way you travel,
              making it not just a journey, but an unforgettable experience. We
              are committed to providing seamless, efficient, and innovative
              solutions that simplify the complexities of flight booking and
              enhance your overall travel adventure. Our values are deeply
              rooted in customer satisfaction, integrity, and continuous
              improvement. We strive to exceed expectations by prioritizing
              transparency, reliability, and personalized service in everything
              we do. With a passion for travel and a dedication to excellence,
              we aim to inspire and empower every traveler to explore the world
              with confidence and ease. Join us on this journey, and let
              FlyProview be your trusted companion in the skies.
            </h5>
            <h5 className="fw-normal mt-3">
              <GiCheckMark className="text--gold me-2" /> Save Time & Effort
            </h5>
            <h5 className="fw-normal">
              <GiCheckMark className="text--gold me-2" /> Ensure Transparancy
            </h5>

            <h5 className="fw-normal">
              <GiCheckMark className="text--gold me-2" /> Offer Competitive
              Price
            </h5>
            <h5 className="fw-normal">
              <GiCheckMark className="text--gold me-2" /> Empower Travelers
            </h5>
          </div>
          <div className="col-lg-6 my-3">
            <img
              src={flightTicekts}
              className="img-fluid rounded-3 tockets"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsComp;
