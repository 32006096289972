import React from "react";
import { Link } from "react-router-dom";

const AgreementSweedish = () => {
  return (
    <>
      <h2>Användaravtal</h2>
      <p>
        “ Användaravtal för Elektroniska Tjänster mellan Ultra Tech Sweden AB
        och Användare av <Link to="/" className="text-decoration-underline">www.Flyproview.com</Link> “
      </p>
      <h4>1. Bakgrund</h4>
      <p>
        Ultra Tech Sweden AB ("Företaget") tillhandahåller elektroniska tjänster
        genom sin online resebyråplattform, tillgänglig via webbplatsen
        <Link to="/" className="text-decoration-underline"> www.Flyproview.com</Link> ("Webbplatsen"). Genom att använda Webbplatsen,
        inklusive att söka, boka eller besöka den, ingår användaren av
        tjänsterna ("Användaren") detta användaravtal ("Avtalet") med Företaget.
        Samtliga rättigheter till design, unika funktioner och
        upphovsrättigheter ägs av Företaget, med organisationsnummer
        <span className="fw-bold"> 559441-8765</span>, och alla försök att
        kopiera eller stjäla dess design eller funktioner är förbjudna enligt
        gällande lagar.
      </p>
      <h4>2. Tjänster och Användarrättigheter</h4>
      <p className="mb-0">
        {" "}
        <span className="fw-bold">2.1 </span>
        Genom att använda Webbplatsen och dess tjänster bekräftar Användaren att
        ha ingått detta Avtal med Företaget. Användaren har rätt att använda de
        tjänster som tillhandahålls på Webbplatsen enligt detta Avtal och
        överenskomna villkor.
      </p>

      <p className="mb-0">
        {" "}
        <span className="fw-bold">2.2 </span>
        Användaren kan söka, boka och utföra reseaktiviteter via Webbplatsen.
        Användaren ansvarar för korrekt och korrekt information vid bokning och
        för att följa villkoren som tillhandahålls av tredje part, inklusive
        flygbolag och hotell.
      </p>
      <p>
        {" "}
        <span className="fw-bold">2.3 </span>
        Användaren har inte rätt att kopiera, distribuera, modifiera,
        reproducera eller använda Webbplatsens design, unika funktioner eller
        annat material för kommersiella ändamål eller på sätt som strider mot
        detta Avtal eller tillämpliga lagar.
      </p>

      <h4>3. Personuppgifter</h4>
      <p className="mb-0">
        {" "}
        <span className="fw-bold">3.1 </span>
        Användningen av personuppgifter regleras av Företagets
        integritetspolicy. Genom att använda tjänsterna samtycker Användaren
        till att Företaget behandlar personuppgifter enligt denna policy.
      </p>

      <p>
        {" "}
        <span className="fw-bold">3.2 </span>
        Företaget ansvarar för att behandla och skydda personuppgifter i
        enlighet med tillämpliga dataskyddslagar.
      </p>

      <h4>4. Begränsning av ansvar</h4>
      <p className="mb-0">
        {" "}
        <span className="fw-bold">4.1 </span>
        Företaget ansvarar inte för några förluster eller skador som Användaren
        kan lida på grund av felaktig användning, fel, brister i tjänsterna
        eller andra omständigheter. Eventuella anspråk mot Användaren som
        uppstår på grund av användningen av tjänsterna ligger på Användaren.
      </p>

      <p>
        {" "}
        <span className="fw-bold">4.2 </span>
        Företaget ansvarar inte för ändringar eller anpassningar som Användaren
        gör i samband med användningen av tjänsterna. Eventuella avtal och
        dokument som ingår i tjänsterna bör användas som exempel och kan kräva
        individuell anpassning för varje enskilt fall.
      </p>

      <h4>5. Upphovsrätt och licensiering</h4>
      <p className="mb-0">
        {" "}
        <span className="fw-bold">5.1 </span>
        Innehållet på Webbplatsen, inklusive design, funktioner och dokument, är
        skyddat enligt tillämpliga upphovsrättslagar. Användaren erhåller rätten
        att använda tjänsterna och dokumenten enligt överenskomna villkor vid
        ingåendet av detta Avtal.
      </p>

      <p>
        {" "}
        <span className="fw-bold">5.2 </span>
        Användaren har inte rätt att kopiera, distribuera eller använda
        Företagets material eller tjänster för kommersiella ändamål eller på
        sätt som strider mot upphovsrättslagar eller detta Avtal.
      </p>

      <h4>6. Begränsning till EU-medborgare</h4>
      <p className="mb-0">
        {" "}
        <span className="fw-bold">6.1 </span>
        Företaget säljer endast biljetter och bokar hotellboenden till personer
        som är medborgare i Europeiska unionen (EU). Användaren erkänner att
        tjänsterna är avsedda uteslutande för EU-medborgare.
      </p>

      <p>
        {" "}
        <span className="fw-bold">6.2 </span>
        Företaget ansvarar inte om systemet används på ett sätt som möjliggör
        att icke-EU-medborgare får tillgång till tjänsterna. Användaren
        samtycker till att eventuell obehörig användning av tjänsterna av
        icke-EU-medborgare inte är Företagets ansvar.
      </p>

      <h4>7. Tvister och tillämplig lag</h4>
      <p className="mb-0">
        {" "}
        <span className="fw-bold">7.1 </span>
        Detta Avtal och användningen av tjänsterna regleras av svensk lag.
      </p>

      <p>
        {" "}
        <span className="fw-bold">7.2 </span>
        Eventuella tvister som uppstår i samband med detta Avtal ska lösas i
        allmän domstol vid Stockholms tingsrätt.
      </p>

      <p>
        Genom att använda Webbplatsen och dess tjänster godkänner Användaren
        detta Avtal och åtar sig att följa dess villkor.
      </p>
    </>
  );
};

export default AgreementSweedish;
