import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import apis from 'services';
import { useNavigate } from 'react-router-dom';

const stripePromise = loadStripe('pk_test_51NaGD5BJdBka6qy38zlOuQDC0sZ1lpzrzcsGxnI652eCr2UkqNPwdENQzhIPa6OTYSuvl7NsEOFUjwKTBX7o7heC00DcWCewyw'); // Replace with your actual Stripe publishable key

const CheckoutForm = () => {
  const navigate = useNavigate();
  
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if (stripe && elements) {
      const pr = stripe.paymentRequest({
        country: 'US', // Change based on your location
        currency: 'usd',
        total: {
          label: 'Total',
          amount: 2000, // Change this to your total amount
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          if (result.applePay || result.googlePay) {
            setPaymentRequest(pr);
          } else {
            console.log('Google Pay or Apple Pay is not available');
          }

          pr.on('paymentmethod', (ev) => {
            console.log(ev,"ebent triggered when payemnt successfully made")
            navigate("/")
            ev.complete('success')
            
            //this event is triggered at the completion of the saved payment 
            //interface
            //we don't care about the actual payment request anymore
            //now that we have a paymentMethod, we can check out as normal
            //the checkout function I am not including here, but is very similar 
            //to the 'create a subscription' stripe guide
            //however it is important to note that I am passing in the event as an 
            //argument.  Once checkout receives a response from my server as to 
            //wether the transaction was successful or not, use 
            //ev.complete('success') and ev.complete('fail') accordingly to close
            // //the modal or display an error message (browser specific behavior)
            // checkout(ev.paymentMethod.id, ev);
          });
        } else {
          console.error('Payment request cannot be made:', result);
        }
      }).catch((error) => {
        console.error('Error checking payment methods:', error);
      });
    }
  }, [stripe, elements]);

  useEffect(() => {
    if (paymentRequest) {
      const paymentRequestButton = elements.create('paymentRequestButton', {
        paymentRequest: paymentRequest,
      });

      paymentRequestButton.mount('#payment-request-button');
    }
  }, [paymentRequest, elements]);

  const handleCheckout = async () => {
    const { data } = await apis.createChecoutSession({
      items: [
        { name: 'Product 1', price: 2000, quantity: 1,currency:"usd" },
        // Add more items as needed
      ],
    });

    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId: data.id,
    });

    if (error) {
      console.error('Error redirecting to checkout:', error);
    }
  };

  return (
    <div>
      <div id="payment-request-button"></div>
      <button onClick={handleCheckout}>Checkout</button>
    </div>
  );
};

const StripeWrapper = () => (
  <Elements stripe={stripePromise}>
    <CheckoutForm />
  </Elements>
);

export default StripeWrapper;
