export default function formatDuration(durationStr) {
    // Remove the leading "PT" from the duration string
    durationStr = durationStr.slice(2);
    // Extract hours and minutes from the duration string
    let hours = 0;
    let minutes = 0;
    if (durationStr.includes('H')) {
      const [hoursPart, rest] = durationStr.split('H');
      hours = parseInt(hoursPart, 10);
      durationStr = rest;
    }
    if (durationStr.includes('M')) {
      minutes = parseInt(durationStr.slice(0, -1), 10);
    }
  
    // Create a human-readable format for the duration
    let durationText = '';
    if (hours > 0) {
      durationText += `${hours}h `;
    }
    if (minutes > 0) {
      durationText += ` ${minutes}min`;
    }
  
    return durationText.trim();
  }
  
   function formatDurationForDays(durationStr) {
    durationStr = durationStr.slice(2);
    let hours = 0;
    if (durationStr.includes('H')) {
      const [hoursPart, rest] = durationStr.split('H');
      hours = parseInt(hoursPart, 10);
      durationStr = rest;
    }
    return hours;
  }
  
  export { formatDurationForDays}
